export default [
  {
    path: "sms-manage", name: "sms-manage", component: () => import("../views/index"), meta: { title: "" },
    redirect: { name: "AdminWebSettings" },
    children: [
      {path: 'sms-list', name: "SmsList", component: () => import("../views/sms-list"),
        // meta: {title: "短信列表"}
        },
    ]
  },
]
