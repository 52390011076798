export default [
  {
    path: 'ai',
    name: 'AI',
    component: { render: (h) => h('router-view') },
    // meta: { title: "" },
    redirect: { name: 'AISettings' },
    children: [
      {
        path: 'ai-settings',
        name: 'AISettings',
        component: () => import('../views/ai-settings.vue'),
        meta: { title: 'ai配置' },
      }
    ],
  },
]
